import React from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Grid, Text, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"
import ImageAndText from "../components/blocks/imageAndText"
import ImagesAndText from "../components/blocks/imagesAndText"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import TitleAndBody from "../components/blocks/titleAndBody"
import GridGallery from "../components/blocks/gridGallery"
import ContactForm from "../components/blocks/contactFrom"
import { ArrowDown } from "react-feather"
import RichContentStructuredText from "../components/richContentStructuredText"

const Home = ({ data: { page, site, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  var blockIds = page.content.map(block => {
    return {
      id: block.id,
      title: block.content ? block.content.kicker : block.kicker,
    }
  })

  return (
    <Layout locale={page.locale} pageSections={blockIds} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>

      <Box
        sx={{
          minHeight: ["100vh"],
          height: ["auto", "auto"],
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: ["100%"],
            width: "100%",
            zIndex: 0,
            backgroundColor: "dark",
            ".gatsby-image-wrapper": {
              height: ["100%"],
              width: "100%",
            },
            video: {
              height: "100%",
              width: "100%",
              objectFit: "cover",
            },
            ":before": {
              content: "''",
              position: "absolute",
              background: "rgba(0, 0, 0, .5)",
              borderRadius: "5px",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
            },
            overflow: "hidden",
          }}
        >
          {page.heroVideo && (
            <video
              title={"background-video"}
              id=""
              class=""
              autoplay="autoPlay"
              muted="muted"
              loop="loop"
              playsinline="playsInline"
            >
              <source src={page.heroVideo.url} type="video/mp4" />
            </video>
          )}
        </Box>

        <Container
          variant="oneSideRight"
          sx={{
            height: "100%",
            position: "relative",
            pt:[9,9,9,11],
            pb: [9, 9],
            zIndex: 1,
          }}
        >
          <Box
            sx={{ borderBottom: "4px solid", borderColor: "light", pb: [4] }}
          >
            <Box>
              <Text as="p" variant="kicker">
                <span>{page.kicker}</span>
              </Text>
            </Box>
            <Box sx={{ h1: { color: "light", fontSize:[7,9] }, mt: [4] }}>
              <RichContentStructuredText text={page.body} />
            </Box>
          </Box>

          <Flex
            sx={{
              alignItems: "center",
              color: "light",
              justifyContent: "center",
              mt: [6],
              px: [6],
            }}
          >
            <Box>Scorri per continuare</Box>
            <Box>
              <ArrowDown size={24} />
            </Box>
          </Flex>
        </Container>
      </Box>
      {page.content.map(block => (
        <Box as="section" id={block.id} key={block.id}>
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              kicker={block.content.kicker}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
              frame={block.frame}
              link={block.link}
            />
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              kicker={block.kicker}
              title={block.title}
              body={block.body}
              image={block.image}
            />
          )}
          {block.model.apiKey === "images_and_text" && (
            <ImagesAndText
              kicker={block.content.kicker}
              title={block.content.title}
              body={block.content.body}
              images={block.images}
            />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel
              kicker={block.kicker}
              title={block.title}
              items={block.items}
            />
          )}
          {block.model.apiKey === "title_and_body" && (
            <TitleAndBody
              title={block.content.title}
              body={block.content.body}
            />
          )}
          {block.model.apiKey === "grid_gallery" && (
            <GridGallery
              kicker={block.kicker}
              title={block.title}
              body={block.body}
              images={block.gallery}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
        </Box>
      ))}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      kicker
      title
      heroVideo {
        url
      }
      body {
        value
        links
        blocks
      }
      locale
      content {
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsBlogPage {
                  ...BlogDetails
                }
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                ... on DatoCmsArticleCategory {
                  ...ArticleCategoryDetails
                  ...ArticleCategoryAllSlugLocales
                }
              }
            }
            ... on DatoCmsExternalLink {
              id
              anchor
              url
              model {
                apiKey
              }
            }
            ... on DatoCmsDocument {
              id
              title
              documents {
                url
              }
            }
          }
          frame
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          kicker
          title
          subtitle
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          body {
            value
            links
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImagesAndText {
          id
          images {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          kicker
          title
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          model {
            apiKey
          }
        }
        ... on DatoCmsGridGallery {
          id
          kicker
          title
          body {
            blocks
            links
            value
          }
          gallery {
            title
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
