import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"

const OrderedList = ({ kicker, title, body, image }) => {
  return (
    <Box sx={{ position: "relative", py: [4, 6] }}>
      <Grid
        columns={[1, 1,1, "1fr 1fr"]}
        sx={{ backgroundColor: "darkBg" }}
        gap={[0]}
      >
        {image && image.gatsbyImageData && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              ".gatsby-image-wrapper": {
                overflow: "unset",
                height: "100%",
                width: "100%",
                position: "relative",
                overflow:"hidden",
                img:{
                  objectFit:"contain!important",
                  height:"auto",
                  width:"100%",
                  top: "50%",
                  transform: "translateY(-50%)"
                }
              },
            }}
          >
            <GatsbyImage image={image.gatsbyImageData} alt="" />
          </Box>
        )}
        <Flex sx={{ py: [4,6],px:[3,4], width:["100%","80%","80%","80%"], flexDirection: "column" }}>
          <Box>
            <Text as="p" variant="kicker">
              <span>{kicker}</span>
            </Text>
          </Box>
          <Box>
            <Heading as="h2" sx={{ color: "light" }} variant="h2">
              {title}
            </Heading>
          </Box>
          <Box
            sx={{
              li: {
                pl:"0!important",
                ml:"0!important",
                "&::before":{
                  display:"none"
                },
                borderBottom: "1px solid",
                pb: [3],
                borderColor: "primary",
                fontWeight:300,
                "strong":{
                  fontWeight:"500"
                }
              },
            }}
          >
            <RichContentStructuredText text={body} theme="dark" />
          </Box>
        </Flex>
      </Grid>
    </Box>
  )
}

export default OrderedList
