import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { OutboundLink } from "../link"

const ImageAndText = ({ kicker, title, body, image , frame, link}) => {
  return (
    <Box sx={{ position: "relative", py: [4, 6] }}>
      <Container>
        <Grid columns={[1, 1, ".85fr 1.15fr"]} gap={[3,4,8]}>
          <Flex sx={{ py: [6], flexDirection: "column" }}>
            <Box>
              <Text as="p" variant="kicker">
                <span>{kicker}</span>
              </Text>
            </Box>
            <Box>
              <Heading as="h2" variant="h2">
                {title}
              </Heading>
            </Box>
            <Box variant="divider"></Box>
            <Box
              sx={{
                pr: [3],
                p: {
                  mb: [1],
                },
              }}
            >
              <RichContentStructuredText text={body} />
            </Box>
            {
              link && 
              <Box sx={{my:[5]}}>
                 <OutboundLink href={link.documents[0].url}  variant="buttons.primary">
                   {link.title}
                 </OutboundLink>
              </Box>
            }
          </Flex>
          {image && image.gatsbyImageData && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                ".gatsby-image-wrapper": {
                  overflow: "unset",
                  height: "100%",
                  width: frame ? "85%" : "90%",
                  position: "relative",
                  left: frame ?[5]:0,
                  top: frame ? [5]:0,
                  img: {
                    objectFit: !frame && "contain!important",
                    boxShadow: frame && "0px 0px 20px rgba(0,0,0,.1)",
                  },
                  "&:before": {
                    left: frame && [-5],
                    top: frame && [-5],
                    content:frame &&  "''",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    border: "15px solid",
                    borderColor: "primary",
                  },
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
