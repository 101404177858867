import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
  Link,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ContactForm = ({
  kicker,
  title,
  subtitle,
  privacyPolicyDescription,
  newsletterDescription,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    company: "",
    telefono: "",
    city: "",
    province: "",
    message: "",
    agreementPrivacyCookies: false,
    agreementNewsLetter: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          setLoading(false)
        })
    else setLoading(false)
  }

  return (
    <Box
      sx={{
        py: [3, 3, 3, 3],
        backgroundColor: "primary",
      }}
    >
      <Container name="contactForm">
        <Box>
          <Grid columns={["1fr", "1fr", "1fr", ".4fr .6fr"]} gap={[1, 1, 1, 6]}>
            <Box>
              <Heading
                as="h2"
                variant="h1"
                sx={{
                  my: [4],
                  color: "dark",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "dark",
                  },
                }}
              >
                {title}
              </Heading>
              <Heading
                as="h3"
                variant="h3"
                sx={{
                  color: "dark",
                  fontWeight: 300,
                  my: [0, 4],
                }}
              >
                {subtitle}
              </Heading>
            </Box>
            {!success ? (
              <Box as="form" onSubmit={sendMail} sx={{ my: [4] }}>
                <Box>
                  <Grid
                    sx={{
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                    gap={[0, 0, 0, 0]}
                  >
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              fullName: e.target.value,
                            }))
                          }
                          name="nome cognome"
                          placeholder="Nome e Cognome*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              company: e.target.value,
                            }))
                          }
                          name="ragione sociale"
                          placeholder="Azienda*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                          name="email"
                          type="email"
                          placeholder="Email*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              phone: e.target.value,
                            }))
                          }
                          name="phone"
                          type="text"
                          placeholder="Telefono*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              city: e.target.value,
                            }))
                          }
                          name="city"
                          placeholder="Comune*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              province: e.target.value,
                            }))
                          }
                          name="province"
                          placeholder="Provincia*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>

                    <Textarea
                      sx={{
                        fontFamily: "body",
                        fontSize: [2],
                      }}
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          message: e.target.value,
                        }))
                      }
                      name="message"
                      placeholder="Messaggio"
                      variant={"inputs.primary"}
                      type="textarea"
                    />
                    <LabeledCheckbox
                      checkedCheckbox={e => {
                        setFormData(prevState => ({
                          ...prevState,
                          agreementPrivacyCookies: e,
                        }))
                      }}
                      required={true}
                    >
                      <Box>
                        <Text
                          variant="p"
                          sx={{
                            fontSize: [0],
                            color: "dark",
                            "a":{
                              color:"dark"
                            }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: privacyPolicyDescription,
                          }}
                        />
                      </Box>
                    </LabeledCheckbox>
                    {console.log(
                      "agreementNewsLetter",
                      formData.agreementNewsLetter
                    )}
                    <LabeledCheckbox
                      checkedCheckbox={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          agreementNewsLetter: e,
                        }))
                      }
                    >
                      <Box>
                        <Text
                          variant="p"
                          sx={{ fontSize: [0], "a":{
                            color:"dark"
                          } }}
                          dangerouslySetInnerHTML={{
                            __html: newsletterDescription,
                          }}
                        />
                      </Box>
                    </LabeledCheckbox>
                    {!success ? (
                      <Box>
                        <Button
                          type="submit"
                          title={
                            !formData.agreementPrivacyCookies
                              ? "Acconsenti al trattamenti dei dati personali"
                              : ""
                          }
                          sx={{
                            mt: [3, 3, 3, 3],
                            opacity: !formData.agreementPrivacyCookies && 0.7,
                          }}
                          variant={"buttons.primaryForm"}
                        >
                          <Flex
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {loading ? "Caricamento..." : "Invia richiesta"}
                          </Flex>
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        <Button
                          type="submit"
                          sx={{ mt: [3, 3, 3, 3] }}
                          variant="buttons.full.primaryForm"
                          disabled
                        >
                          <Flex
                            sx={{
                              justifyContent: "center",
                              justifyItems: "center",
                              alignItems: "center",
                            }}
                          >
                            RICHIESTA INVIATA
                          </Flex>
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Flex>
                <Heading sx={{ my: [4], color: "dark" }} as="h4">
                  Richiesta inviata!
                </Heading>
              </Flex>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "dark",
        "input:checked~.css-kydphz": {
          color: "dark",
        },
        svg: {
          color: "dark",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "dark",
          "input:checked~&": {
            color: "dark",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
