import React, { useState } from "react"
import { Box, Grid, Heading, Text, Flex } from "@theme-ui/components"
// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"
SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const ItemCarousel = ({ kicker, title, items }) => {
  // console.log(items)
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <Box sx={{ position: "relative", py: [4, 6] }}>
      <Grid
        columns={["1fr ", "1fr", "1fr", ".25fr .75fr"]}
        sx={{
          minHeight: ["auto", "auto", "auto", "700px"],
          backgroundColor: "darkBg",
        }}
        gap={[0]}
      >
        <Flex sx={{ p: [3,4],py:[4,6], flexDirection: "column" }}>
          <Box>
            <Text as="p" variant="kicker">
              <span>{kicker}</span>
            </Text>
          </Box>
          <Box>
            <Heading as="h2" variant="h2" sx={{ color: "light" }}>
              {title}
            </Heading>
          </Box>
        </Flex>
        <Box sx={{ height: "100%" }}>
          <Swiper

            autoHeight={true}
            grabCursor={true}
            centeredSlidesBounds={true}
            navigation={{ prevEl, nextEl }}
            pagination={true}
            breakpoints={{
              320: {
                slidesPerView: 1.2,
              },
              640: {
                slidesPerView: 2.2,
              },
              1020: {
                slidesPerView: 3.2,
              },
            }}
            id={"mySwiper imageGallery"}
          >
            {items.map((item, index) => (
              <SwiperSlide key={item.originalId}>
                <Item richContent={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Grid>
    </Box>
  )
}

const Item = ({ richContent }) => {
  const index = useBreakpointIndex

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        "&:hover": {
          ".kicker-carousel-item": {
            opacity: 1,
          },
          "&:after": {
            opacity: 1,
          },
        },
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          opacity: index > 2 ? 0 : 1,
          zIndex: 1,
          background: "rgb(0,0,0)",
          background:
            "linear-gradient(180deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: ["100%"],
          width: "100%",
          zIndex: -1,
          backgroundColor: "dark",
          ".gatsby-image-wrapper": {
            height: ["100%"],
            width: "100%",
            img: {
              objectPosition: "60% 50%",
            },
          },
          overflow: "hidden",
        }}
      >
        {richContent.image && (
          <GatsbyImage
            image={richContent.image.gatsbyImageData}
            alt={richContent.image.gatsbyImageData}
          />
        )}
      </Box>
      <Flex
        className="kicker-carousel-item"
        sx={{
          position: "relative",
          opacity: index > 2 ? 0 : 1,
          zIndex: 2,
          height: ["100%"],
          p: 5,
        }}
      >
        <Box>
          <Box sx={{ minHeight: ["400px", "400px"] }}>
            <Heading
              as="h3"
              sx={{
                fontSize: [5],
                fontWeight: 400,
                lineHeight: "30px",
                color: "primary",
                mt: 0,
              }}
            >
              {richContent.title}
            </Heading>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default ItemCarousel
