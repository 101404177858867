import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"

const ImagesAndText = ({ kicker, title, body, images }) => {
  return (
    <Box sx={{ position: "relative", py: [4, 6] }}>
      <Container>
        <Grid columns={[1, 1, ".70fr 1.30fr"]} gap={[3,4,7]}>
          <Flex sx={{ flexDirection: "column" }}>
            <Box>
              <Text as="p" variant="kicker">
                <span>{kicker}</span>
              </Text>
            </Box>
            <Box>
              <Heading as="h2" variant="h2" sx={{ mt: [3] }}>
                {title}
              </Heading>
            </Box>
            <Box variant="divider"></Box>
            <Box
              as="div"
              sx={{
                pr: [3],
                ul: { color: "dark", li: { py: [0, 0], mb: "0!important" } },
                p: {
                  my: [6],
                },
              }}
            >
              <RichContentStructuredText text={body} />
            </Box>
          </Flex>
          <Grid
            sx={{
              gridTemplateRows: ["1fr 1fr"],
              height: "100%",
              width: "100%",
            }}
            columns={[1, 2]}
            gap={[5]}
          >
            {images && images[0] && images[0].gatsbyImageData && (
              <Box
                sx={{
                  gridRowStart: ["unset","unset",1],
                  gridRowEnd: ["unset","unset",2],
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    overflow: "unset",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    img: {
                      objectFit: "contain!important",
                    },
                  },
                }}
              >
                <GatsbyImage image={images[0].gatsbyImageData} alt="" />
              </Box>
            )}
            {images && images[1] && images[1].gatsbyImageData && (
              <Box
                sx={{
                  mt: [0,0,-7],
                  gridRowStart: ["unset",2,2],
                  gridRowEnd: ["unset",2,3],
                  gridColumn: [1,2,2],
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    bottom: 0,
                    overflow: "unset",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    img: {
                      objectFit: "contain!important",
                    },
                  },
                }}
              >
                <GatsbyImage image={images[1].gatsbyImageData} alt="" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImagesAndText
