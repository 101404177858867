import React from "react"
import { Box, Grid, Heading, Flex, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"

const GridGallery = ({ kicker, title, body, images }) => {
  return (
    <Box>
      <Container>
        <Grid columns={[1, 1, "1fr 1fr"]} gap={[0,32]}>
          <Flex sx={{ flexDirection: "column" }}>
            <Box>
              <Text as="p" variant="kicker">
                <span>{kicker}</span>
              </Text>
            </Box>
            <Box>
              <Heading as="h2" variant="h2" sx={{ lineHeight: 1, mt: [3] }}>
                {title}
              </Heading>
            </Box>
            <Box variant="divider"></Box>
          </Flex>
          <Box
            as="div"
            sx={{
              my: [4],
              pr: [3],
              ul: { color: "dark", li: { py: [0, 0], mb: "0!important" } },
              p: {
                my: [6],
              },
            }}
          >
            <RichContentStructuredText text={body} />
          </Box>
        </Grid>
        <Grid columns={[1, 1, "1fr 1fr"]} gap={[3,4]} sx={{ py: [4, 6] }}>
          {images.map(image => (
            <Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  ".gatsby-image-wrapper": {
                    overflow: "unset",
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} alt="" />
                <Heading as="h3" variant="h4" sx={{color:"light", width:"60%", fontWeight:400,position: "absolute", bottom:[5], left:[4] }}>
                  {image.title}
                </Heading>
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default GridGallery
